import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    steps: [],
    pages: [],
    business_functions: [],
    business_function: {},
    job_role: null,
    agents: [],
  },
  mutations: {
    setBusinessFunctions(state, payload) {
      state.business_functions = payload;
    },
    setBusinessFunction(state, payload) {
      state.business_function = payload;
    },
    setJobRole(state, payload) {
      state.job_role = payload;
    },
    setPages(state, payload) {
      state.pages = payload;
    },
    setAgents(state, payload) {
      state.agents = payload;
    },
    setSteps(state, payload) {
      state.steps = payload;
    },
  },
  actions: {
    async fetchBusinessFunctions({ commit }) {
      const response = await axios.get('/api/v2/careers/business_functions_simple');
      commit('setBusinessFunctions', response.data);
    },
    async fetchBusinessFunction({ commit }, urlParams) {
      const response = await axios.get(`/api/v2/careers/business_functions/${urlParams.id}`);
      commit('setBusinessFunction', response.data);
    },
    async fetchJobRoles({ commit }) {
      const response = await axios.get('/api/v2/careers/job_roles');
      commit('setJobRoles', response.data);
    },
    async fetchJobRole({ commit }, urlParams) {
      const url = `/api/v2/careers/business_functions/${urlParams.parent_id}/job_roles/${urlParams.id}`;
      const response = await axios.get(url);
      commit('setJobRole', response.data);
    },
    async fetchPages({ commit }) {
      const response = await axios.get('/api/v2/pages');
      commit('setPages', response.data.items);
    },
    async fetchAgents({ commit }) {
      const response = await axios.get('/api/v2/careers/agents');
      commit('setAgents', response.data);
    },
    async fetchSteps({ commit }) {
      const response = await axios.get('/api/v2/careers/steps');
      commit('setSteps', response.data);
    },
  },
  getters: {
    getBusinessFunctions: (state) => {
      return state.business_functions;
    },
    getBusinessFunction: (state) => {
      return state.business_function;
    },
    getJobRole: (state) => state.job_role,
    getPages: (state) => {
      try {
        return state.pages;
      } catch (ex) {
        return [];
      }
    },
    getAgents: (state) => state.agents,
    getAgent: (state) => state.agents[0],
    getSteps: (state) => state.steps,
  },
});
