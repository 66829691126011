<template>
    <section id="function-pathway">
      <div class="container">
        <div class="content-wrapper">
            <h2>So, what is a typical career pathway?</h2>
            <p>Here, you’ll discover the various roles along this pathway that share common
               skills and knowledge.</p>
            <p><strong>Select the links below and explore how you can progress.</strong></p>
        </div>
      </div>
    </section>
</template>

<style lang="scss" scoped>
#function-pathway {
    background-image: url('~@/assets/images/pathway-image2.png');
    background-repeat: no-repeat;
    background-position: right 0;
}
#function-pathway .container {
    padding-bottom: 15px;
}
#function-pathway .container .content-wrapper {
    width: 540px;
    padding: 91px;
}
#function-pathway h2 {
    font-size: 3em;
}
#function-pathway .content-wrapper p {
    margin-bottom: 10px;
}
@media only screen and (min-width: 320px) and (max-device-width: 480px) {
  #function-pathway {
    background-position: unset;
    background-size: cover;
    height: 600px;
  }
  .container {
    width: 90%;
    padding: 11px 0;
  }
  #function-pathway .container .content-wrapper {
    width: 100%;
  }
  #function-pathway h2 {
    font-size: 1.6em;
  }
  h2 {
    margin: 0 0 10px 0;
  }
  #function-pathway h2 + p {
    width: 85%;
  }
  a, li, p {
    font-size: 1em;
  }
  #function-pathway h2 + p + p {
    margin-top: 360px;
    width: 65%;
  }
  #function-pathway .container .content-wrapper {
    padding: 27px;
  }
}
</style>
