<template>
  <footer>
    <div class="container">
      <div class="content-wrapper">
        <div>
          <h4 class="slogan">Helping Britain Achieve <span>Net Zero</span></h4>
        </div>
        <div class="socials grid">
          <a href="https://www.facebook.com/edfenergy" class="fa fa-facebook"></a>
          <a href="https://www.instagram.com/edfenergy" class="fa fa-instagram"></a>
          <a href="https://www.linkedin.com/company/edf-energy" class="fa fa-linkedin"></a>
          <a href="https://www.twitter.com/edfenergy" class="fa fa-twitter"></a>
          <a href="https://www.youtube.com/EDFEnergy" class="fa fa-youtube"></a>
        </div>
      </div>
      <div class="copyright">
        <p>EDF is a trading name used by EDF Energy Customers Ltd. Reg. No. 02228297. Registered
          office is 90 Whitfield Street, London, W1T 4EZ, incorporated in England and Wales.</p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>

footer {
    background-color: $secondary-colour;
}
footer .container {
    padding: 55px 0;
}
footer .content-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: 100% 100%;

}
footer .grid{
    display: grid;
    grid-auto-flow: column;
    text-align: center;
}
footer .socials {
    padding-bottom: 40px;
}
footer h4.slogan {
    font-size: 1.3em;
}
footer .grid a, footer h4 {
    color: $default-white;
}
footer h4.slogan span {
    font-size: 1.2em;
}
footer .slogan span {
  color: $primary-colour;
}
footer .socials .fa {
    display: inline-block;
    border-radius: 60px;
    padding: 0.2em;
    width: 50px;
    height: 50px;
  font-size: 2.5em;
  text-decoration: none;
  background: $default-white;
  margin-right: 25px;
  color: $secondary-colour;
}
footer .socials .fa:hover {
  opacity: 0.7;
}

footer .copyright p {
    font-size: 0.8em;
    color: $default-white;
}
@media only screen and (min-width: 320px) and (max-device-width: 480px) {
  .container {
    width: 90%;
    padding: 11px 0;
  }
  footer .content-wrapper {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
  footer .socials .fa {
    margin-right: 8px;
  }
}
</style>
