import { createApp } from 'vue';
import axios from 'axios';
import AppLink from '@/components/AppLink.vue';
import router from './router';
import store from './store';
import App from './App.vue';

axios.defaults.baseURL = process.env.VUE_APP_API_ROOT_URL;

createApp(App)
  .component('AppLink', AppLink)
  .use(store)
  .use(router, axios)
  .mount('#app');
