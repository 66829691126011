<template>
  <div class="main-wrapper">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss">
@import "./styles/global.scss";

* {
    color: $grey-300;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.main-wrapper {
  width: 1440px;
  margin: auto;
}
.container {
    width: 1000px;
    margin: auto;
    padding: 40px 0;
}
h1 {
    font-size: 3.5em;
}
h1, h2 {
    color: $secondary-colour;
}
h2 {
    margin: 0 0 20px 0;
    font-size: 3em;
}
h3 {
    font-size: 2em;
    margin: 0 0 30px 0;
}
strong, b {
    font-size: 1em;
}
p {
    margin-top: 0;
}
.primary {
    background-color: $primary-colour;
}
.senary {
    background-color: $senary-colour;
}
.secondary {
    background-color:  $secondary-colour;
}
.primary p, .secondary p, .senary p {
    color: $default-white;
}
#steps.secondary .container .grid .step div p {
  color: $secondary-colour;
}
.secondary h2, .senary h2, .btn-secondary span {
    color: $default-white;
}
.btn {
    text-align: center;
    margin: auto;
    max-width: 80%;
    padding: 10px 45px;
}

@media only screen and (min-width: 320px) and (max-device-width: 480px) {
  .main-wrapper {
    width: 100%;
  }
}
</style>
