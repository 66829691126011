import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import JobRolePage from '@/views/JobRolePage.vue';
import BusinessFunctionPage from '@/views/BusinessFunctionPage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/business_functions/:id',
    name: 'BusinessFunction',
    component: BusinessFunctionPage,
  },
  {
    path: '/business_functions/:parent_id/job_roles/:id',
    name: 'JobRole',
    component: JobRolePage,
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export default router;
